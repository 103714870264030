import * as React from "react";

import "./Heading.css";

export default function Heading() {
  return (
    <header>
      <h1>
        Hi <span className="hand">👋</span>
      </h1>
    </header>
  );
}
